import React from 'react'

interface MarkerIconProps {
  fill: string;
}

const MarkerIcon: React.FC<MarkerIconProps> = ({ fill }) => {
  return (
    <svg width="53" height="53" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="15" fill={fill} />
      <circle cx="45" cy="45" r="45" fill={fill} fillOpacity="0.2" />
    </svg>
  );
};

export default MarkerIcon;
