import axios from 'axios'
import GetPointsApi from '../../../ui/homepage/Map/generateLocations/api/get-points'
export interface InvoiceResponse {
	invoice_link: string
}

const useInvoiceApi = () => {
	const host = process.env.REACT_APP_BOT_URL
	const { getPoints } = GetPointsApi()
	const getInvoiceAddress = async (
		amount: number,
		type: number,
		name: string
	) => {
		try {
			const response = await axios.post<InvoiceResponse>(`${host}/donate`, {
				description: name,
				amount: amount,
				type: type,
			})
			setTimeout(() => {
				getPoints()
			}, 1000)
			return response.data?.invoice_link
		} catch (error) {
			console.error('Error fetching invoice address:', error)
		}
	}

	return { getInvoiceAddress }
}
export default useInvoiceApi
