import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import AnimatedNumber from '../../../hooks/animatedNumber'
import { useNecessary } from '../../../hooks/necessary'
import { buildings } from '../../../models/buildings'
import { achievementImages } from '../../../pages/achievementpage/GetAchieveIcons'
import useApi from '../../../requestProvider/apiHandler'
import Arrow from '../../images/ArrowCity.svg'
import Coin from '../../images/homepage/coin.svg'
import Lock from '../../images/homepage/Lock.svg'
import useAchieveState, { Achievement } from '../../store/achieveState'
import buildState from '../../store/buildState'
import useStore from '../../store/zustand'
import BuyBuildingModal from './BuyBuildingModal'
import CityBuildingFilter from './Filter'
import { GetIcon } from './GetIcons'

interface SwipeUpPanelProps {
	height?: string
}

const SwipeUpPanel: React.FC<SwipeUpPanelProps> = ({ height = '75vh' }) => {
	const [open, setOpen] = useState(false)
	const [visibleHeight, setVisibleHeight] = useState('')
	const panelRef = useRef<HTMLDivElement>(null)
	const handleRef = useRef<HTMLDivElement>(null)
	const [filter, setFilter] = useState<string>('East')
	const navigate = useNavigate()
	const { achievement } = useAchieveState()
	const { t } = useTranslation()
	const groupedAchievements = achievement.reduce<Record<string, Achievement[]>>(
		(acc, item) => {
			if (!acc[item.name]) {
				acc[item.name] = []
			}
			acc[item.name].push(item)
			return acc
		},
		{}
	)

	function canOpenBox(
		status: string | null,
		rankNeedToBuy: string | null,
		type: string | null,
		name: string | null
	): boolean {
		const rankHierarchy: (string | null)[] = [
			null,
			'bronze',
			'silver',
			'gold',
			'diamond',
		]
		const userRankIndex = rankHierarchy.indexOf(status)
		const requiredRankIndex = rankHierarchy.indexOf(rankNeedToBuy)

		if (type && name) {
			const achievement = groupedAchievements[type]?.find(s => s.type === name)
			if (achievement?.claimed === true) {
				return true
			} else {
				return false
			}
		}

		return (
			userRankIndex >= requiredRankIndex &&
			userRankIndex !== -1 &&
			requiredRankIndex !== -1
		)
	}

	const heightValue = parseFloat(height)

	const [{ y }, api] = useSpring(() => ({
		y: heightValue - parseFloat(visibleHeight),
	}))

	useEffect(() => {
		api.start({ y: open ? 0 : heightValue - parseFloat(visibleHeight) })
	}, [open, heightValue, visibleHeight, api])

	useEffect(() => {
		const handleResize = () => {
			setVisibleHeight(
				window.innerHeight <= 670
					? '42vh'
					: window.innerHeight <= 770
					? '47vh'
					: '51vh'
			)
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const apii = useApi()
	const { buildings, total_friends } = buildState()
	const { getBuildings, getData } = useNecessary()
	const { coin_per_hour, status } = useStore()
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [modalInfo, setModalInfo] = useState<buildings>()

	const filteredBuildings = useMemo(
		() => buildings.filter(building => building.state === filter),
		[buildings, filter]
	)

	const buyBuilding = useCallback(async () => {
		setOpenModal(false)
		await apii({
			method: 'POST',
			url: `/building/buy?building_id=${modalInfo?.id}`,
		})
		getData()
		getBuildings()
	}, [apii, modalInfo?.id, getData, getBuildings])

	const colorAchieveBuilding = (type: string, name: string) => {
		if (type && name) {
			const achievement = groupedAchievements[type]?.find(s => s.type === name)
			if (achievement) {
				return achievement.color
			}
		}
		return undefined
	}

	const isAchieveBuilding = (type: string, name: string) => {
		if (!type || !name) return null

		const achievement = groupedAchievements[type]?.find(s => s.type === name)
		if (achievement && achievement.claimed === false) {
			return achievementImages[type]?.[name] || null
		}
		return null
	}

	return (
		<>
			<animated.div
				ref={panelRef}
				style={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					height,
					backgroundColor: '#464646',
					borderRadius: '30px 30px 0px 0px',
					transform: y.to(y => `translateY(${y}vh)`),
					touchAction: 'none',
				}}
			>
				<div
					ref={handleRef}
					className='h-[55px] items-center flex justify-center text-white text-2xl font-bold bg-myColors-100'
					onClick={() => setOpen(!open)}
				>
					<img
						src={Arrow}
						className='absolute top-[8px] right-1 w-[35px] h-[35px]'
					/>
					<div className='relative'>
						<img src={Coin} alt='coin' className='w-[35px] h-[45px] mt-2' />
					</div>
					<p className='mt-2'>{<AnimatedNumber value={coin_per_hour} />}/h</p>
				</div>

				<CityBuildingFilter filter={filter} setFilter={setFilter} />

				<div
					className={`grid grid-cols-2 gap-2 custom-sm:gap-3 justify-items-center ${
						open ? 'pb-[32vh]' : 'pb-[53vh]'
					} w-full px-2 overflow-y-scroll overflow-x-hidden h-[calc(85vh-55px)] pt-1`}
				>
					{filteredBuildings.map((building, index) => {
						const isLocked =
							filter === 'Downtown'
								? !canOpenBox(
										status,
										building.rank_need_to_buy,
										building.type,
										building.achievement
								  )
								: building.friend_to_lvl_up > total_friends

						return (
							<div
								key={index}
								className='bg-myColors-750 flex flex-col rounded-lg w-[180px] h-[110px] cursor-pointer custom-sm:w-[165px]'
								onClick={() => {
									if (!isLocked) {
										setModalInfo(building)
										setOpenModal(true)
									}
								}}
							>
								<div className='flex gap-1 px-2 my-2'>
									<div className='relative'>
										{isLocked && (
											<img
												src={Lock}
												className='absolute bg-black bg-opacity-75 w-full h-full rounded-[6px] p-4 border border-black'
											/>
										)}
										<GetIcon iconType={building.icon_type} />
									</div>
									<div className='flex flex-col'>
										<p
											className='text-[9px]'
											style={{
												color: colorAchieveBuilding(
													building.type || '',
													building.achievement || ''
												),
											}}
										>
											{building.name}
										</p>
										<p className='text-[7px] opacity-80 mt-2'>
											{t('cityEarnPerTime')}: <br />
										</p>
										<p className='text-[8px] opacity-80 text-myColors-250'>
											{t('current')} {building.per_hour} GT/h
										</p>
										{building.lvl < 10 ? (
											<p className='text-[8px] opacity-80'>
												{t('level')} {building.lvl + 1} -{' '}
												{building.next_lvl_price} GT/h
											</p>
										) : (
											<p className='text-[8px] opacity-80'>{t('maxed')}</p>
										)}
									</div>
								</div>
								<div className='flex w-full justify-between text-sm'>
									<p className='p-1 bg-myColors-100 w-[60px] rounded-l-lg text-center'>
										lvl {building.lvl}
									</p>
									{filter === 'Downtown' ? (
										!canOpenBox(
											status,
											building.rank_need_to_buy,
											building.type,
											building.achievement
										) ? (
											<button
												className='text-center text-[10px] bg-myColors-400 w-full p-1 rounded-r-lg flex justify-center items-center gap-2'
												onClick={() =>
													building.achievement === null
														? navigate('/premium')
														: navigate('/achievements')
												}
											>
												{isAchieveBuilding(
													building.type || '',
													building.achievement || ''
												) ? (
													<>
														{t('upgradeTo')}
														<img
															src={
																isAchieveBuilding(
																	building.type || '',
																	building.achievement || ''
																) || ''
															}
															className='w-[20px] h-[20px] rounded-full'
															alt=''
														/>
													</>
												) : (
													<div className='flex gap-1'>
														<p>{t('subscribeTo')}</p>
														<p>{building?.rank_need_to_buy_translated}</p>
													</div>
												)}
											</button>
										) : (
											<p className='text-center bg-myColors-400 w-full p-1 rounded-r-lg'>
												{building.price}GT
											</p>
										)
									) : building?.friend_to_lvl_up > total_friends ? (
										<p className='text-center bg-myColors-400 w-full p-1 rounded-r-lg text-[11px]'>
											{t('invite_friends', {
												count: building?.friend_to_lvl_up,
											})}
										</p>
									) : (
										<p className='text-center bg-myColors-400 w-full p-1 rounded-r-lg'>
											{building.price}GT
										</p>
									)}
								</div>
							</div>
						)
					})}
				</div>
			</animated.div>

			{openModal && modalInfo && (
				<BuyBuildingModal
					setModalInfo={setModalInfo}
					modalInfo={modalInfo}
					buyBuilding={buyBuilding}
					setOpenModal={setOpenModal}
				/>
			)}
		</>
	)
}

export default memo(SwipeUpPanel)
