import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { formatDateString } from '../../../../hooks/convertDate'
import { useNecessary } from '../../../../hooks/necessary'
import SuccessSnackbar from '../../../../hooks/successSnackbar'
import { GameData } from '../../../../models/gameData'
import useStore from '../../../store/zustand'
import { getDistance, UpdateMapCenter } from '../../../utils/mapUtils'
import ClaimPopup from '../../Popup/ClaimPopup'
import LevelUpModal from '../LevelUp'
import ReachLocation from '../reachLocation/reach-location'
import { LocationInfo } from '../type'
import ClaimLocationModal from './ClaimLocationModal'
import GetPointsApi from './generateLocations/api/get-points'
import GenerateLocations from './generateLocations/generate-locations'
import { GetIcon } from './GetIcon'
import './map.css'
import MapMode from './MapMode'

interface Props {
	gameData: GameData[]
	handleClick: (closeid: string) => void
	setError: any
	open: boolean
}

export interface ModalInfoInt {
	diamonds: number
	reward: number
}

interface Marker {
	name: string
	position: [number, number]
	showClick: boolean
	placeCount: number
	total: number
	smile?: string
	description?: string
	diamond_reward?: number
	reward: number
	id: string
	start_at: string
	point?: {
		name: string
		color: string
		description: string
		type: string
		icon: string
	}
}

const createCustomIcon = (text: string, emoji: string) => {
	return L.divIcon({
		html: `
      <div class="royal-marker">
         <span style="font-size: 16px; opacity: 70%; text-align: center; border-radius: 50%; line-height: 1.2;">
					${emoji}
				</span>
        <div class="marker-text text-myColors-250">${text}</div>
      </div>
    `,
		className: '',
		iconSize: [25, 25],
	})
}

function Map({ gameData, handleClick, setError }: Props) {
	const positionRef = useRef<[number, number] | null>(null)
	const [markers, setMarkers] = useState<Marker[]>([])
	const [generatedPoints, setGeneratedPoints] = useState<Marker[]>([])
	const { t } = useTranslation()
	const once = useRef(false)
	const { getData } = useNecessary()
	const [message, setMessage] = useState<string>('')
	const [ClaimModal, setClaimModal] = useState<boolean>(false)
	const { setVirtualClick, setMaxVirtualClick, virtualClick, maxVirtualClick } =
		useStore()
	const [modalInfo, setModalInfo] = useState<ModalInfoInt>({
		reward: 0,
		diamonds: 0,
	})
	const [prevUserLvl, setPrevUserLvl] = useState<null | number>(null)
	const [locationInfo, setLocationInfo] = useState<LocationInfo>({
		user_lvl: null,
		claimed_count: 0,
		generate_chance: 0,
		claimed_need_to_reward: 0,
		generate_max_chanced: 0,
		virtual_picks: 0,
		reward: 0,
	})
	const bounds = L.latLngBounds([-85, -180], [85, 180])
	const [popupHelper, setPopupHelper] = useState<boolean>(false)
	const mode = localStorage.getItem('themeMode')
	const [theme, setTheme] = useState(mode)
	const [levelupModal, setLevelUpModal] = useState<boolean>(false)

	if (!mode) {
		localStorage.setItem('themeMode', 'dark')
	}

	const popupVisible = () => {
		setPopupHelper(true)
		setTimeout(() => {
			setPopupHelper(false)
		}, 2500)
	}

	const { setPoints, claimLocation, getPoints, claimVirtual } = GetPointsApi()

	const getPointsReq = async () => {
		if (positionRef.current) {
			const res = await setPoints(
				positionRef.current[0],
				positionRef.current[1]
			)
			if (res) {
				setGeneratedPoints(
					res.points.map(res => {
						const markerPosition: [number, number] = [
							res.latitude,
							res.longitude,
						]
						const distanceToMarker = getDistance(
							positionRef.current as [number, number],
							markerPosition
						)
						return {
							name: 'generated',
							position: markerPosition,
							showClick: distanceToMarker <= 50,
							placeCount: 0,
							total: 0,
							diamond_reward: res?.diamond_reward,
							reward: res.reward,
							id: res.id,
							start_at: '',
							point: res?.point,
						}
					})
				)
				setMaxVirtualClick(res?.max_virtual_picks)
				setVirtualClick(res?.virtual_picks)
				setLocationInfo({
					user_lvl: res.user_lvl,
					claimed_count: res?.claimed_count,
					generate_chance: res.generated_today,
					generate_max_chanced: res.generate_location_max_count,
					claimed_need_to_reward: res.claimed_need_to_reward,
					virtual_picks: res.virtual_picks,
					reward: res.reward,
				})
			}
		}
	}

	const getPointsRes = async () => {
		const res = await getPoints()
		if (res && positionRef.current) {
			setGeneratedPoints(
				res.points.map(point => {
					const markerPosition: [number, number] = [
						point.latitude,
						point.longitude,
					]
					const distanceToMarker = getDistance(
						positionRef.current as [number, number],
						markerPosition
					)

					return {
						name: 'generated',
						position: markerPosition,
						showClick: distanceToMarker <= 50,
						placeCount: 0,
						total: 0,
						diamond_reward: point?.diamond_reward,
						reward: point.reward,
						id: point.id,
						start_at: '',
						point: point?.point,
					}
				})
			)
			setMaxVirtualClick(res?.max_virtual_picks)
			setVirtualClick(res?.virtual_picks)
			setLocationInfo({
				user_lvl: res.user_lvl,
				claimed_count: res.claimed_count,
				generate_chance: res.generated_today,
				generate_max_chanced: res.generate_location_max_count,
				claimed_need_to_reward: res.claimed_need_to_reward,
				virtual_picks: res.virtual_picks,
				reward: res.reward,
			})
		}
	}

	useEffect(() => {
		if (!once.current && positionRef.current) {
			getPointsRes()
			once.current = true
		}
	}, [positionRef.current])

	useEffect(() => {
		const watchId = navigator.geolocation.watchPosition(
			pos => {
				const { latitude, longitude } = pos.coords
				const newPosition: [number, number] = [latitude, longitude]
				positionRef.current = newPosition

				const updatedMarkers = gameData
					.map(data => {
						const markerPosition: [number, number] = [
							data.geolocation_x,
							data.geolocation_y,
						]
						const distanceToMarker = getDistance(newPosition, markerPosition)
						return {
							name: '',
							position: markerPosition,
							showClick: distanceToMarker <= 50,
							placeCount: data.place_count,
							total: data.total,
							smile: data.smile,
							description: data.description,
							reward: data.game_reward,
							id: data.id,
							start_at: data.start_at,
						}
					})
					.filter(marker => marker !== null)

				setMarkers(updatedMarkers)

				setGeneratedPoints(prevPoints =>
					prevPoints.map(point => {
						const distanceToGeneratedMarker = getDistance(
							newPosition,
							point.position
						)
						return {
							...point,
							showClick: distanceToGeneratedMarker <= 100,
						}
					})
				)
			},
			console.error,
			{
				enableHighAccuracy: true,
				maximumAge: 1000,
				timeout: 5000,
			}
		)
		return () => navigator.geolocation.clearWatch(watchId)
	}, [gameData])

	const claimGeneratedVirtual = async (
		id: string,
		reward: number,
		diamond: number
	) => {
		setModalInfo({
			reward: reward,
			diamonds: diamond,
		})

		if (positionRef.current) {
			const res = await claimVirtual(id)
			getData()
			if (res) {
				if (res?.notify_user === true) {
					setClaimModal(true)
				}
				setMessage(t('snackbarSuccess'))
				setTimeout(() => {
					setMessage('')
				}, 2000)
				setGeneratedPoints(
					res.points.map(point => {
						const markerPosition: [number, number] = [
							point.latitude,
							point.longitude,
						]
						const distanceToMarker = getDistance(
							positionRef.current as [number, number],
							markerPosition
						)
						return {
							name: 'generated',
							position: markerPosition,
							showClick: distanceToMarker <= 50,
							placeCount: 0,
							total: 0,
							reward: point?.reward,
							diamond_reward: point?.diamond_reward,
							id: point.id,
							start_at: '',
							point: point?.point,
						}
					})
				)
				setMaxVirtualClick(res?.max_virtual_picks)
				setVirtualClick(res?.virtual_picks)

				setLocationInfo({
					user_lvl: res.user_lvl,
					claimed_count: res.claimed_count,
					generate_chance: res.generated_today,
					generate_max_chanced: res.generate_location_max_count,
					claimed_need_to_reward: res.claimed_need_to_reward,
					virtual_picks: res.virtual_picks,
					reward: res.reward,
				})
			}
		}
	}

	const claimGenerated = async (
		id: string,
		reward: number,
		diamond: number
	) => {
		setModalInfo({
			reward: reward,
			diamonds: diamond,
		})
		if (positionRef.current) {
			const res = await claimLocation(id)
			getData()
			if (res) {
				if (res?.notify_user === true) {
					setClaimModal(true)
				}
				setMessage(t('snackbarSuccess'))
				setTimeout(() => {
					setMessage('')
				}, 2000)
				setGeneratedPoints(
					res.points.map(point => {
						const markerPosition: [number, number] = [
							point.latitude,
							point.longitude,
						]
						const distanceToMarker = getDistance(
							positionRef.current as [number, number],
							markerPosition
						)

						return {
							name: 'generated',
							position: markerPosition,
							showClick: distanceToMarker <= 50,
							placeCount: 0,
							total: 0,
							reward: point.reward,
							diamond_reward: point?.diamond_reward,
							id: point.id,
							start_at: '',
							point: point?.point,
						}
					})
				)
				setMaxVirtualClick(res?.max_virtual_picks)
				setVirtualClick(res?.virtual_picks)
				setLocationInfo({
					user_lvl: res.user_lvl,
					claimed_count: res.claimed_count,
					generate_chance: res.generated_today,
					generate_max_chanced: res.generate_location_max_count,
					claimed_need_to_reward: res.claimed_need_to_reward,
					virtual_picks: res.virtual_picks,
					reward: res.reward,
				})
			}
		}
	}

	const combinedMarkers = [...markers, ...generatedPoints]
	const closestMarker = combinedMarkers.find(marker => marker.showClick)

	useEffect(() => {
		const mapElement = document.querySelector(
			'.leaflet-container'
		) as HTMLElement
		if (mapElement) {
			mapElement.style.backgroundColor = theme === 'dark' ? 'black' : 'white'
		}
	}, [theme])

	function hexToRgb(hex: string) {
		hex = hex.replace(/^#/, '')
		let bigint = parseInt(hex, 16)
		let r = (bigint >> 16) & 255
		let g = (bigint >> 8) & 255
		let b = bigint & 255
		return `${r}, ${g}, ${b}`
	}

	useEffect(() => {
		if (
			prevUserLvl !== null &&
			(locationInfo.user_lvl as number) > prevUserLvl
		) {
			console.log(prevUserLvl)

			setLevelUpModal(true)
		}
		setPrevUserLvl(locationInfo.user_lvl)
	}, [locationInfo.user_lvl])

	return (
		<div className='relative'>
			<div
				className={`w-full flex flex-col justify-center items-center`}
				style={{ position: 'relative', width: '100%' }}
			>
				<div className='absolute top-[-1px] w-full' style={{ zIndex: 9999 }}>
					<ReachLocation locationInfo={locationInfo} />
				</div>
				<MapContainer
					center={positionRef.current || [0, 0]}
					zoom={18}
					style={{
						height: '800px',
						width: '100%',
						margin: '0 auto',
						position: 'relative',
					}}
					attributionControl={false}
					zoomControl={false}
				>
					<TileLayer
						url={
							theme === 'dark'
								? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
								: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
						}
						attribution='&copy; <a href="https://carto.com/">CartoDB</a> contributors'
					/>
					{positionRef.current && (
						<>
							<UpdateMapCenter position={positionRef.current} />
							<Marker
								position={positionRef.current}
								icon={L.divIcon({ className: 'user-marker' })}
							/>
							{combinedMarkers.map((marker, index) =>
								marker.name !== 'generated' ? (
									<Marker
										key={index}
										position={marker.position}
										icon={createCustomIcon(
											`${marker.placeCount}/${marker.total}`,
											marker.smile || ''
										)}
									>
										<Popup>
											<div
												className='flex flex-col gap-0'
												style={{ margin: 0, padding: 0 }}
											>
												<p
													className='text-myColors-300'
													style={{ margin: 0, padding: 0 }}
												>
													{formatDateString(marker.start_at)}
												</p>
												<p
													className='text-[12px] text-white'
													style={{ marginTop: '12px', margin: 0 }}
												>
													{marker.description}
												</p>
											</div>
											<p
												className='flex gap-1 text-[11px] text-white'
												style={{ margin: 0, paddingTop: '10px' }}
											>
												{t('adminReward')}:
											</p>
											<p
												className='text-myColors-250 text-[11px] font-bold'
												style={{ margin: 0, padding: 0 }}
											>
												{marker.reward === -1
													? t('claimedReward')
													: marker.reward}
											</p>
											<button className='mt-2 bg-myColors-250 text-white font-bold px-5 p-2 rounded-xl'>
												{t('checkGooleMap')}
											</button>
										</Popup>
									</Marker>
								) : (
									<Marker
										key={index}
										position={marker.position}
										icon={L.divIcon({
											html: `
                      <div class="custom-marker" style="background: ${
												marker.point?.color
											}; box-shadow: 0 0 12px 12px rgba(${hexToRgb(
												marker.point?.color || ''
											)}, 0.5);">
                          <div class="marker-icon"></div>
                      </div>
                    `,
											className: '',
											iconSize: [20, 20],
										})}
									>
										<Popup>
											<div className='flex items-center relative'>
												<div className='flex items-center gap-2 h-[90px]'>
													<GetIcon
														icon={marker?.point?.icon || ''}
														color={marker?.point?.color || ''}
													/>
													<div
														className='max-w-[220px] w-full flex flex-col h-full'
														style={{ padding: 0 }}
													>
														<p
															className='text-[13px] w-full font-semibold'
															style={{
																color: `${marker?.point?.color}`,
																margin: 0,
															}}
														>
															{marker?.point?.type}
														</p>
														<p
															className='text-[9px] w-full text-white font-semibold'
															style={{ margin: 0 }}
														>
															{marker?.point?.name}
														</p>
														<p
															className='text-[10px] text-white opacity-40 w-full'
															style={{ margin: 0 }}
														>
															{marker?.point?.description}
														</p>
													</div>
												</div>
												{virtualClick > 0 ? (
													<button
														className='bg-myColors-250 text-white font-bold px-2 p-2 absolute bottom-[-24px] right-[-40px] text-[12px] rounded-full'
														onClick={() =>
															claimGeneratedVirtual(
																marker.id,
																marker.reward,
																marker?.diamond_reward || 0
															)
														}
													>
														{t('virtual_claim')}
													</button>
												) : null}
											</div>
										</Popup>
									</Marker>
								)
							)}
						</>
					)}
				</MapContainer>

				<div className='fixed z-[999] bottom-[105px] left-5'>
					<GenerateLocations
						getPointsReq={getPointsReq}
						position={positionRef.current}
						locationInfo={locationInfo}
					/>
				</div>

				<div className={`fixed text-white z-[999] bottom-[105px]`}>
					{closestMarker && closestMarker?.reward !== -1 ? (
						<button
							className='w-[95px] h-[95px] p-2 bg-opacity-35 bg-black rounded-full flex items-center justify-center'
							onClick={() =>
								closestMarker?.name === ''
									? handleClick(closestMarker?.id)
									: claimGenerated(
											closestMarker.id,
											closestMarker?.reward,
											closestMarker?.diamond_reward || 0
									  )
							}
						>
							<div className='w-full h-full p-1 bg-black rounded-full flex items-center justify-center flex-col'>
								<p className='text-center text-[14px]'>{t('im_here')}</p>
								<p className='text-myColors-250 text-[12px]'>
									{closestMarker?.reward} GT
								</p>
							</div>
						</button>
					) : (
						<div
							className='w-[95px] h-[95px] p-2 bg-opacity-35 bg-black rounded-full flex items-center justify-center relative'
							onClick={popupVisible}
						>
							<div className='w-full opacity-35 h-full p-1 bg-black rounded-full flex items-center justify-center flex-col'>
								<p className='text-center text-[14px]'>{t('im_here')}</p>
								<p className='text-myColors-250 text-[12px]'>
									{locationInfo?.reward || 0} GT
								</p>
							</div>
							{popupHelper && <ClaimPopup />}
						</div>
					)}
				</div>
			</div>
			<MapMode theme={theme || ''} setTheme={setTheme} />
			<SuccessSnackbar message={message} />
			{ClaimModal && (
				<ClaimLocationModal
					modalInfo={modalInfo}
					setClaimModal={setClaimModal}
				/>
			)}
			{levelupModal && (
				<LevelUpModal
					setLevelUpModal={setLevelUpModal}
					locationInfo={locationInfo}
				/>
			)}
		</div>
	)
}

export default Map
