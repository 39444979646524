import { t } from 'i18next'
import Close from "../../../../components/images/airdrop/close.svg"
import useAchieveState from "../../../../components/store/achieveState"

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
}

export default function AchieveModalInfo({ open, setOpen }: Props) {
  const { typeInfo } = useAchieveState();

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 text-white transition-opacity duration-300 ease-in-out">
      <div className="bg-myColors-800 border border-myColors-850 rounded-[35px] py-2 pb-4 px-4 md:max-w-md mx-2 z-60 transform w-[350px] transition-transform duration-300 ease-in-out max-h-[84vh] h-full overflow-scroll">
        <div className="flex justify-end mt-2">
          <button
            className="w-[30px] h-[30px] flex justify-center items-center"
            onClick={() => setOpen(false)}
          >
            <img src={Close} alt="Close" className="w-[30px] h-[30px]" />
          </button>
        </div>

        <div>
          <h2 className="text-2xl font-bold text-center">{t("badges")}</h2>
          <div className="mt-4 gap-3 flex flex-col">
            {typeInfo.length > 0 ? (
              typeInfo.map((info, index) => (
								<div>
								<p className='font-bold text-white'>{info.type}</p>
								<p className='font-light'><span className='text-myColors-250'>{t('goal')}:</span> {t('goalCount', { count: info.goal })}</p>
								<p className='font-light'><span className='text-myColors-250'>{t('prize')}:</span> {info.reward} GT {info.reward_diamonds > 0 && `+ ${info.reward_diamonds} 💎`} {info.reward_buildings > 0 && `+ ${t('open_building', { level: info.reward_buildings })}`}</p>
								</div>
              ))
            ) : (
              <p className="text-center">{t('loading')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}