
import { Dispatch, SetStateAction } from 'react'
import { ReactComponent as Close } from '../../../images/airdrop/close.svg'
import { LocationInfo } from '../type'
interface Props {
	setLevelUpModal: Dispatch<SetStateAction<boolean>>
	locationInfo: LocationInfo
}
export default function LevelUpModal({setLevelUpModal, locationInfo}:Props) {
	return (
	<div className="fixed inset-0 z-[10000] bg-opacity-50 bg-black flex items-center justify-center text-white">
			<div
				className={`bg-myColors-800 border border-myColors-850 rounded-[35px] py-2 pb-4 px-4 md:max-w-md mx-2 z-60 transform w-[350px] transition-transform duration-300 ease-in-out`}
			>
			<div className='flex justify-end'>
				<Close
					className='w-[25px]'
				/>
			</div>
			<p className='text-center font-bold text-xl mt-3'>🎉 Congratulations! You Leveled Up! 🎉</p>
			<p className='text-center mt-5'>
				Level {locationInfo.user_lvl}!
			</p>
			<div className='flex gap-5 justify-center items-center mt-10'>
				<button
					className='p-2 px-4 bg-myColors-500 font-bold w-[130px] rounded-3xl'
					style={{ boxShadow: '0 4px 25px rgba(247, 174, 59, 1)' }}
					onClick={()=>setLevelUpModal(false)}
				>
					Okay
				</button>
			</div>
		</div>
	</div>
	)
}