import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { abbreviateNumber } from '../../hooks/convertNumber'
import { useTelegram } from '../../hooks/useTelegram'
import CrownIcon from '../images/header/crown.svg'
import { ReactComponent as Settings } from '../images/header/settings.svg'
import { ReactComponent as Coin } from '../images/homepage/coin.svg'
import { ReactComponent as Logo } from '../images/logo.svg'
import useStore from '../store/zustand'
const Header = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { status, balance, virtualClick, maxVirtualClick } = useStore()

	const getFlagComponent = () => {
		switch (status) {
			case 'gold':
				return <p className='text-2xl'>🟡</p>
			case 'bronze':
				return <p className='text-2xl'>🟤</p>
			case 'silver':
				return <p className='text-2xl'>⚪</p>
			case 'diamond':
				return <p className='text-2xl'>💎</p>
			default:
				return (
					<button className='relative rounded-2xl bg-black w-[50px] h-[45px] flex justify-center items-center bg-opacity-55 cursor-pointer'>
						<img src={CrownIcon} alt='' className='h-[20px] w-[30px]' />
					</button>
				)
		}
	}

	const { user } = useTelegram()
	const { lvl, diamonds } = useStore()

	const navigateToLevelPage = () => {
		navigate('/level')
	}

	return (
		<>
			{/* <div
        className={`relative h-[21vh] custom-sm:h-[25vh] w-full opacity-55`}
        style={{backgroundColor: '#232323'}}
      >
      </div> */}
			<div className='fixed top-[8vh] w-full px-5'>
				<div className='flex w-full justify-center'>
					<button
						className='flex gap-1 items-center cursor-pointer bg-myColors2-200 p-2 rounded-[30px]'
						onClick={navigateToLevelPage}
						onTouchStart={navigateToLevelPage}
					>
						<div>
							<Logo className='w-[35px] h-[35px]' />
						</div>

						<div className='flex flex-col text-[12px] text-white'>
							<p className='text-left'>@{user}</p>
							<p className='opacity-45 ml-[2px]'>
								{t('level')} {lvl} - {diamonds}💎
							</p>
						</div>
					</button>
				</div>
				<div className='flex w-full justify-between mt-2'>
					<button
						className='rounded-2xl bg-black w-[50px] h-[50px] flex justify-center items-center bg-opacity-55 cursor-pointer text-2xl'
						onClick={() => navigate('/achievements')}
					>
						🏅
					</button>

					<div className='flex justify-between items-center'>
						<div className='flex gap-3 items-center rounded-2xl bg-black bg-opacity-[40%]'>
							<button
								className='relative rounded-2xl bg-black w-[50px] h-[50px] flex justify-center items-center bg-opacity-55 cursor-pointer'
								onClick={() => navigate('/premium')}
							>
								{getFlagComponent()}
							</button>

							<div className='flex flex-col text-white gap-1'>
								<div className='text-center text-[12px] flex gap-1 items-center justify-center'>
									<Coin className='w-5 h-5' />
									<p>{abbreviateNumber(balance)}</p>
								</div>
							</div>

							<div className='rounded-2xl bg-black w-[50px] h-[50px] flex justify-center items-center bg-opacity-55'>
								<Settings
									className='w-[20px]'
									onClick={() => navigate('/Settings')}
								/>
							</div>
						</div>
					</div>

					<div
						className='rounded-2xl bg-black w-[50px] h-[50px] flex justify-center items-center bg-opacity-55 cursor-pointer flex-col leading-none'
						onClick={() => navigate('/virtual-pick')}
					>
						<p>🚁</p>
						<p className='text-myColors-250'>
							{virtualClick}/{maxVirtualClick}
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default Header
