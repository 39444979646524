
import { t } from 'i18next'
import styles from './style.module.css'
export default function ClaimPopup() {
	return (
	<div className={styles.popup}>
        <div className={styles.arrowUp}></div>
        <div className={styles.popupContent}>
        <h2 dangerouslySetInnerHTML={{ __html: t('reward_instruction') }} />
        </div>
    </div>
	)
}