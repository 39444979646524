import publicSafety1 from '../../../components/images/achievepage/Public Savety/1.webp'
import publicSafety2 from '../../../components/images/achievepage/Public Savety/2.webp'
import publicSafety3 from '../../../components/images/achievepage/Public Savety/3.webp'
import publicSafety4 from '../../../components/images/achievepage/Public Savety/4.webp'
import publicSafety5 from '../../../components/images/achievepage/Public Savety/5.webp'

import health1 from '../../../components/images/achievepage/Health/1.webp'
import health2 from '../../../components/images/achievepage/Health/2.webp'
import health3 from '../../../components/images/achievepage/Health/3.webp'
import health4 from '../../../components/images/achievepage/Health/4.webp'
import health5 from '../../../components/images/achievepage/Health/5.webp'

import fire1 from '../../../components/images/achievepage/Fire/1.webp'
import fire2 from '../../../components/images/achievepage/Fire/2.webp'
import fire3 from '../../../components/images/achievepage/Fire/3.webp'
import fire4 from '../../../components/images/achievepage/Fire/4.webp'
import fire5 from '../../../components/images/achievepage/Fire/5.webp'

import sanitation1 from '../../../components/images/achievepage/Sanitation/1.webp'
import sanitation2 from '../../../components/images/achievepage/Sanitation/2.webp'
import sanitation3 from '../../../components/images/achievepage/Sanitation/3.webp'
import sanitation4 from '../../../components/images/achievepage/Sanitation/4.webp'
import sanitation5 from '../../../components/images/achievepage/Sanitation/5.webp'

import parks1 from '../../../components/images/achievepage/Parks/1.webp'
import parks2 from '../../../components/images/achievepage/Parks/2.webp'
import parks3 from '../../../components/images/achievepage/Parks/3.webp'
import parks4 from '../../../components/images/achievepage/Parks/4.webp'
import parks5 from '../../../components/images/achievepage/Parks/5.webp'

import transportation1 from '../../../components/images/achievepage/Transportation/1.webp'
import transportation2 from '../../../components/images/achievepage/Transportation/2.webp'
import transportation3 from '../../../components/images/achievepage/Transportation/3.webp'
import transportation4 from '../../../components/images/achievepage/Transportation/4.webp'
import transportation5 from '../../../components/images/achievepage/Transportation/5.webp'

import housing1 from '../../../components/images/achievepage/Housing/1.webp'
import housing2 from '../../../components/images/achievepage/Housing/2.webp'
import housing3 from '../../../components/images/achievepage/Housing/3.webp'
import housing4 from '../../../components/images/achievepage/Housing/4.webp'
import housing5 from '../../../components/images/achievepage/Housing/5.webp'

import sports1 from '../../../components/images/achievepage/Sports/1.webp'
import sports2 from '../../../components/images/achievepage/Sports/2.webp'
import sports3 from '../../../components/images/achievepage/Sports/3.webp'
import sports4 from '../../../components/images/achievepage/Sports/4.webp'
import sports5 from '../../../components/images/achievepage/Sports/5.webp'

// Education and Culture
import education1 from '../../../components/images/achievepage/Education/1.webp'
import education2 from '../../../components/images/achievepage/Education/2.webp'
import education3 from '../../../components/images/achievepage/Education/3.webp'
import education4 from '../../../components/images/achievepage/Education/4.webp'
import education5 from '../../../components/images/achievepage/Education/5.webp'

import emergency1 from '../../../components/images/achievepage/Emergency/1.webp'
import emergency2 from '../../../components/images/achievepage/Emergency/2.webp'
import emergency3 from '../../../components/images/achievepage/Emergency/3.webp'
import emergency4 from '../../../components/images/achievepage/Emergency/4.webp'
import emergency5 from '../../../components/images/achievepage/Emergency/5.webp'

export const achievementImages: Record<string, Record<string, string>> = {
	public_safety: {
		wooden_badge: publicSafety1,
		bronze_badge: publicSafety2,
		silver_badge: publicSafety3,
		gold_badge: publicSafety4,
		platinum_badge: publicSafety5,
	},
	health_services: {
		wooden_badge: health1,
		bronze_badge: health2,
		silver_badge: health3,
		gold_badge: health4,
		platinum_badge: health5,
	},
	fire_safety: {
		wooden_badge: fire1,
		bronze_badge: fire2,
		silver_badge: fire3,
		gold_badge: fire4,
		platinum_badge: fire5,
	},
	sanitation: {
		wooden_badge: sanitation1,
		bronze_badge: sanitation2,
		silver_badge: sanitation3,
		gold_badge: sanitation4,
		platinum_badge: sanitation5,
	},
	community_engagement: {
		wooden_badge: parks1,
		bronze_badge: parks2,
		silver_badge: parks3,
		gold_badge: parks4,
		platinum_badge: parks5,
	},
	transportation: {
		wooden_badge: transportation1,
		bronze_badge: transportation2,
		silver_badge: transportation3,
		gold_badge: transportation4,
		platinum_badge: transportation5,
	},
	infrastructure_development: {
		wooden_badge: housing1,
		bronze_badge: housing2,
		silver_badge: housing3,
		gold_badge: housing4,
		platinum_badge: housing5,
	},
	sports_and_wellness: {
		wooden_badge: sports1,
		bronze_badge: sports2,
		silver_badge: sports3,
		gold_badge: sports4,
		platinum_badge: sports5,
	},
	education_and_culture: {
		wooden_badge: education1,
		bronze_badge: education2,
		silver_badge: education3,
		gold_badge: education4,
		platinum_badge: education5,
	},
	emergency_management: {
		wooden_badge: emergency1,
		bronze_badge: emergency2,
		silver_badge: emergency3,
		gold_badge: emergency4,
		platinum_badge: emergency5,
	},
}
