import { useEffect, useState } from 'react'
import useStore from '../components/store/zustand'
import '../components/ui/background.css'
import PhysicalGame from '../components/ui/homepage/physicalGame'
import TutorialComponent from '../components/ui/tutorial'
const Homepage = () => {
	const [tutorialModal, setTutorialModal] = useState<boolean>(false)
	const { isFirstVisit, setFirstVisit } = useStore()
	useEffect(() => {
		if (isFirstVisit) {
			setTutorialModal(true)
			setFirstVisit(false)
		}
	}, [isFirstVisit])
	return (
		<>
			<div className='h-full'>
				<PhysicalGame />
				<TutorialComponent
					setOpenModal={setTutorialModal}
					isOpen={tutorialModal}
				/>
			</div>
		</>
	)
}

export default Homepage
