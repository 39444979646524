import { backButton } from '@telegram-apps/sdk'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MarkerIcon from '../../components/images/achievepage/MarkerIcon'
import useAchieveState, {
	Achievement,
} from '../../components/store/achieveState'
import { achievementImages } from './GetAchieveIcons'
import useAchieveApi from './api/get-acheive'
import AchieveModalInfo from './components/ui/AchieveInfoModal'
import AchieveModal from './components/ui/AchieveModal'

const typeColorMapping: Record<string, string> = {
	public_safety: '#2196F3',
	health_services: '#F44336',
	fire_safety: '#FFEB3B',
	sanitation: '#4CAF50',
	community_engagement: '#8BC34A',
	transportation: '#FF9800',
	infrastructure_development: '#795548',
	sports_and_wellness: '#009688',
	education_and_culture: '#9C27B0',
	emergency_management: '#9E9E9E',
}

const badgeOrder = [
	'wooden_badge',
	'bronze_badge',
	'silver_badge',
	'gold_badge',
	'platinum_badge',
]

const Achievementpage = () => {
	const {
		achievement,
		total_claimed_public_safety,
		total_claimed_health_services,
		total_claimed_fire_safety,
		total_claimed_sanitation_waste_management,
		total_claimed_parks_recreation,
		total_claimed_transportation,
		total_claimed_housing_urban_development,
		total_claimed_sports_wellness,
		total_claimed_education_culture,
		total_claimed_environmental_management,
		setTypeInfo,
	} = useAchieveState()
	const [open, setOpen] = useState<boolean>(false)
	const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
	const [selectedAchievement, setSelectedAchievement] =
		useState<Achievement | null>(null)
	const { getTypeAchieves, getAcheieves } = useAchieveApi()
	const once = useRef<boolean>(false)
	const navigate = useNavigate()

	const groupedAchievements = achievement.reduce<Record<string, Achievement[]>>(
		(acc, item) => {
			if (!acc[item.name]) {
				acc[item.name] = []
			}
			acc[item.name].push(item)
			return acc
		},
		{}
	)

	const getTotalClaimed = (category: string) => {
		const categoryLower = category.toLowerCase()
		switch (categoryLower) {
			case 'public_safety':
				return total_claimed_public_safety
			case 'health_services':
				return total_claimed_health_services
			case 'fire_safety':
				return total_claimed_fire_safety
			case 'sanitation':
				return total_claimed_sanitation_waste_management
			case 'community_engagement':
				return total_claimed_parks_recreation
			case 'transportation':
				return total_claimed_transportation
			case 'infrastructure_development':
				return total_claimed_housing_urban_development
			case 'sports_and_wellness':
				return total_claimed_sports_wellness
			case 'education_and_culture':
				return total_claimed_education_culture
			case 'emergency_management':
				return total_claimed_environmental_management
			default:
				return 0
		}
	}

	const handleRedeemClick = (achievement: Achievement) => {
		setSelectedAchievement(achievement)
		setOpen(true)
	}

	const handleOpenInfoModal = (type: string) => {
		setTypeInfo([])
		getTypeAchieves(type)
		setOpenInfoModal(true)
	}

	useEffect(() => {
		const handleBackButton = () => {
			navigate(-1)
		}
		if (backButton) {
			backButton.show()
			backButton.onClick(handleBackButton)

			return () => {
				if (backButton) {
					backButton.offClick(handleBackButton)
					backButton.hide()
				}
			}
		}
	}, [backButton])

	useEffect(() => {
		if (!once.current) {
			getAcheieves()
			once.current = true
		}
	}, [])

	const getTranslatedName = (name: string) => {
		const group = groupedAchievements[name]
		return group ? group[0].name_translated : null
	}

	return (
		<div className='w-full px-5 mt-[10vh]'>
			<p className='text-2xl text-myColors-250 font-bold text-center'>
				{t('achievemets')}
			</p>

			<div className='mt-5 flex flex-col gap-5'>
				{Object.keys(groupedAchievements).map(name => {
					const totalClaimed = getTotalClaimed(name)
					const name_translated = getTranslatedName(name)
					const nearestAchievement = groupedAchievements[name]
						.filter(achieve => !achieve.claimed)
						.sort((a, b) => a.count - b.count)[0]

					const locationsLeft =
						nearestAchievement && nearestAchievement.count > totalClaimed
							? nearestAchievement.count - totalClaimed
							: 0

					return (
						<div key={name} className='flex flex-col'>
							<div className='flex items-center gap-3'>
								<p
									className='text-[17px] font-semibold'
									style={{
										color:
											typeColorMapping[name] ||
											typeColorMapping['emergency_management'],
									}}
								>
									{name_translated}
								</p>
								<p
									className='w-[40px] h-[40px] bg-myColors2-200 rounded-full flex justify-center items-center text-xl text-white'
									onClick={() => handleOpenInfoModal(name)}
								>
									?
								</p>
							</div>
							<p style={{ color: '#757575' }}>
								{t('pickedUp')} - {totalClaimed} {t('locations')}
							</p>
							<div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-3'>
								<div className='flex justify-between'>
									<div className='flex items-center justify-center'>
										<MarkerIcon fill={typeColorMapping[name]} />
									</div>
									{groupedAchievements[name]
										.sort(
											(a, b) =>
												badgeOrder.indexOf(a.type) - badgeOrder.indexOf(b.type)
										)
										.map(achieve => {
											const canClaim =
												totalClaimed >= achieve.count && !achieve.claimed
											const isClaimed = achieve.claimed

											if (isClaimed) {
												return (
													<div key={achieve.id}>
														<img
															src={achievementImages[name]?.[achieve.type]}
															alt={achieve.type}
															className='object-cover rounded-[20px] w-[53px] h-[53px]'
														/>
													</div>
												)
											}

											if (canClaim) {
												return (
													<button
														key={achieve.id}
														className='relative'
														onClick={() => handleRedeemClick(achieve)}
													>
														<img
															src={achievementImages[name]?.[achieve.type]}
															alt={achieve.type}
															className='object-cover rounded-[20px] w-[53px] h-[53px]'
														/>
														<div className='w-[53px] h-[53px] bg-black opacity-75 rounded-[20px] absolute top-0 left-0 z-99' />
														<p className='text-myColors-250 text-[10px] absolute top-[20px] left-[7px]'>
															{t('redeem')}
														</p>
													</button>
												)
											}

											return (
												<div
													key={achieve.id}
													className='w-[53px] h-[53px] bg-myColors2-200 rounded-[20px]'
												/>
											)
										})}
								</div>
								{locationsLeft > 0 && (
									<p
										className='text-[14px] mt-2'
										style={{ color: '#757575' }}
										dangerouslySetInnerHTML={{
											__html: t('achievement_requirement', {
												type: nearestAchievement.type_translated,
												count: locationsLeft,
											}),
										}}
									/>
								)}
							</div>
						</div>
					)
				})}
				{selectedAchievement && (
					<AchieveModal
						open={open}
						setOpen={setOpen}
						achievement={selectedAchievement}
					/>
				)}
				<AchieveModalInfo open={openInfoModal} setOpen={setOpenInfoModal} />
			</div>
		</div>
	)
}

export default Achievementpage
