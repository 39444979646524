import { backButton } from '@telegram-apps/sdk'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PremiumModal from '../../components/features/premiumpage/premiumModal'
import Arrow from '../../components/images/arrow.svg'
import CrownIcon from '../../components/images/header/crown.svg'
import usePackageStore from '../../components/store/packagesState'
import useStore from '../../components/store/zustand'
import { GetPremiumPackagesInt } from './types/PremiumPackagesInt'

export const PremiumPage = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const { packages } = usePackageStore()
	const [modalInfo, setModalInfo] = useState<GetPremiumPackagesInt>()
	const { status } = useStore()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const membershipLevels = ['Bronze', 'Silver', 'Gold', 'Diamond']

	const canPurchase = (packageName: string) => {
		if (status === null) return true

		const currentLevelIndex = membershipLevels.indexOf(
			capitalizeFirstLetter(status)
		)

		const packageLevelIndex = membershipLevels.indexOf(
			capitalizeFirstLetter(packageName)
		)

		return packageLevelIndex > currentLevelIndex
	}

	useEffect(() => {
		const handleBackButton = () => {
			navigate(-1)
		}
		if (backButton) {
			backButton.show()
			backButton.onClick(handleBackButton)

			return () => {
				if (backButton) {
					backButton.offClick(handleBackButton)
					backButton.hide()
				}
			}
		}
	}, [backButton])

	const handleClick = (packageInfo: GetPremiumPackagesInt) => {
		setModalInfo(packageInfo)
		setIsOpen(true)
	}

	const getFlagComponent = (name: string) => {
		switch (name) {
			case 'Gold':
				return <p className='text-xl'>🟡</p>
			case 'Bronze':
				return <p className='text-xl'>🟤</p>
			case 'Silver':
				return <p className='text-xl'>⚪</p>
			case 'Diamond':
				return <p className='text-xl'>💎</p>
			default:
				return null
		}
	}

	const capitalizeFirstLetter = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	return (
		<div className='w-full px-5 mt-[10vh]'>
			<div className='flex flex-col items-center'>
				<img src={CrownIcon} alt='' />
				<p className='text-center text-2xl font-bold text-myColors-250'>
					{t('premium')}
				</p>
			</div>

			<div className='flex flex-col gap-3 mt-5 font-bold text-white'>
				{packages?.map((pack, i) => {
					const isCurrentStatus =
						capitalizeFirstLetter(status || '') ===
						capitalizeFirstLetter(pack?.name)
					const isDisabled = !canPurchase(pack.name)

					return (
						<button
							key={i}
							className={`w-full px-3 p-3 rounded-md flex justify-between items-center h-[46px] bg-myColors-200
								${isDisabled && 'opacity-50 cursor-not-allowed'} 
								${
									isCurrentStatus &&
									'relative border border-gray-500 rounded-lg p-4 shadow-[0_0_15px_#6b7280]'
								}`}
							onClick={() => handleClick(pack)}
							disabled={isDisabled || isCurrentStatus}
						>
							<div className='flex gap-[2px] items-center'>
								{getFlagComponent(pack.name)}
								<p className='text-[12px]'>
									{pack.name_translated} - {pack?.price}⭐
								</p>
							</div>
							{pack.discount !== 0 && (
								<p className='text-[11px] font-light text-myColors-250'>
									Discount {pack?.discount}%
								</p>
							)}
							<img
								src={Arrow}
								alt='arrow'
								className='w-[20px] h-[20px] pt-[2px]'
							/>
						</button>
					)
				})}
			</div>

			{isOpen && modalInfo && (
				<PremiumModal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					modalInfo={modalInfo}
				/>
			)}
		</div>
	)
}

export default PremiumPage
