import { Dispatch, SetStateAction } from 'react'

interface Props {
	theme:string;
	setTheme: Dispatch<SetStateAction<string | null>>
}
export default function MapMode({theme, setTheme}:Props) {
	const handleChangeMode = () => {
		const mode = theme === 'dark' ? 'light' : 'dark'
		setTheme(mode)
		localStorage.setItem('themeMode', mode)
	}

  return (
    <div className='fixed z-[999] bottom-[105px] right-5'>
      <button
        onClick={handleChangeMode}
        className={`w-[85px] h-[85px] p-2 bg-opacity-35 inset-0 rounded-full flex items-center justify-center transition-all duration-300 ease-in-out ${theme === 'dark' ? 'bg-yellow-600' : 'bg-gray-800'}`}
      >
        <div
          className={`w-full h-full p-1 flex-col rounded-full flex items-center justify-center transition-all duration-300 ease-in-out 
            ${theme === 'dark' ? 'bg-yellow-500' : 'bg-gray-500'} ${theme === 'dark' ? 'scale-100' : 'scale-90'}`}
          style={{
            background: theme === 'dark' ? '#FFDD00' : '#3E3E3E',
          }}
        >
          <p className={`text-white text-3xl transition-all duration-300 ease-in-out`}>
            {theme === 'dark' ? '☀️' : '🌑'}
          </p>
        </div>
      </button>
    </div>
  );
}
