import { t } from 'i18next'
import { useState } from 'react'
import Close from '../../../../components/images/airdrop/close.svg'
import { Achievement } from '../../../../components/store/achieveState'
import { useNecessary } from '../../../../hooks/necessary'
import useAchieveApi from '../../api/get-acheive'
import { achievementImages } from '../../GetAchieveIcons'

interface AchieveModalProps {
	open: boolean
	setOpen: (state: boolean) => void
	achievement: Achievement
}

export default function AchieveModal({
	open,
	setOpen,
	achievement,
}: AchieveModalProps) {
	const { claimAcheieve } = useAchieveApi()
	const { getData } = useNecessary()
	const [loading, setLoading] = useState<boolean>(false)
	if (!open) return null
	const imageUrl = achievementImages[achievement.name]?.[achievement.type] || ''

	const handleClaim = async () => {
		setLoading(true)
		await claimAcheieve(achievement.id)
		getData()
		setLoading(false)
		setOpen(false)
	}

	return (
		<div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 text-white transition-opacity duration-300 ease-in-out'>
			<div className='bg-myColors-800 border border-myColors-850 rounded-[35px] py-2 pb-4 px-4 md:max-w-md mx-2 z-60 transform w-[350px] transition-transform duration-300 ease-in-out'>
				<div className='flex justify-end'>
					<button
						className='w-[30px] h-[30px] flex justify-center items-center'
						onClick={() => setOpen(false)}
					>
						<img src={Close} alt='Close' className='w-[30px] h-[30px]' />
					</button>
				</div>
				<div className='flex flex-col items-center'>
					<h3 className='text-lg font-semibold'>
						{achievement.type_translated}
					</h3>
					<img
						src={imageUrl}
						alt={achievement.type}
						className='w-[80px] h-[80px] object-cover rounded-[20px] mt-[15px]'
					/>
					<p className='mt-[27px] text-lg'>{achievement.diamonds}💎</p>
					<p className='text-myColors-250 text-lg'>{achievement.reward} GT</p>
					<p className='text-lg'>
						{achievement.name_translated} {t('building')}
					</p>

					<button
						className='p-2 px-4 mt-[29px] mb-[20px] bg-myColors-500 font-bold w-[130px] rounded-3xl'
						onClick={handleClaim}
						disabled={loading}
						style={{ boxShadow: '0 4px 25px rgba(247, 174, 59, 1)' }}
					>
						{t('redeem')}
					</button>
				</div>
			</div>
		</div>
	)
}
