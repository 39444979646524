import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../18n'
import { ReactComponent as Close } from '../../images/airdrop/close.svg'
import firstImage from '../../images/tutorial/first.jpg'
import foughtImage from '../../images/tutorial/fought.png'
import secondImage from '../../images/tutorial/second.png'
import thirdImage from '../../images/tutorial/third.png'

interface Props {
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
	isOpen: boolean
}

const TutorialComponent = ({ setOpenModal, isOpen }: Props) => {
	const [step, setStep] = useState(0)
	const [tutorialSteps, setTutorialSteps] = useState<
		{ description: JSX.Element[]; image: string; title?: JSX.Element[] }[]
	>([])
	const [imagesLoaded, setImagesLoaded] = useState(false)
	useTranslation()

	const preloadImages = (images: string[]) => {
		return new Promise<void>((resolve, reject) => {
			let loaded = 0
			const total = images.length

			images.forEach(image => {
				const img = new Image()
				img.src = image
				img.onload = () => {
					loaded += 1
					if (loaded === total) resolve()
				}
				img.onerror = reject
			})
		})
	}

	useEffect(() => {
		setTutorialSteps([
			{
				description: t('tutorialStep1Description')
					.split('\n')
					.map((line, index) => (
						<span key={index} className='block'>
							{line}
						</span>
					)),
				image: firstImage,
			},
			{
				description: t('tutorialStep2Description')
					.split('\n')
					.map((line, index) => (
						<span key={index} className='block'>
							{line}
						</span>
					)),
				image: secondImage,
			},
			{
				title: t('tutorialStep3Title')
					.split('\n')
					.map((line, index) => (
						<span key={index} className='block'>
							{line}
						</span>
					)),
				description: t('tutorialStep3Description')
					.replace(/\n/, '<br />')
					.split('\n')
					.map((line, index) => (
						<span
							key={index}
							className='block mt-2'
							dangerouslySetInnerHTML={{ __html: line }}
						/>
					)),
				image: thirdImage,
			},
			{
				title: t('tutorialStep4Title')
					.split('\n')
					.map((line, index) => (
						<span key={index} className='block'>
							{line}
						</span>
					)),
				description: t('tutorialStep4Description')
					.replace(/\n/, '<br />')
					.split('\n')
					.map((line, index) => (
						<span
							key={index}
							className='block mt-2'
							dangerouslySetInnerHTML={{ __html: line }}
						/>
					)),
				image: foughtImage,
			},
		])
	}, [i18n.language])

	useEffect(() => {
		const images = [firstImage, secondImage, thirdImage, foughtImage]
		preloadImages(images)
			.then(() => {
				setImagesLoaded(true)
			})
			.catch(error => {
				console.error('Ошибка при загрузке изображений:', error)
			})
	}, [])

	if (!isOpen || !imagesLoaded) return null

	return (
		<div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 text-white transition-opacity duration-300 ease-in-out'>
			<div className='bg-myColors-800 border border-myColors-850 rounded-[35px] py-4 px-6 md:max-w-md mx-2 z-60 transform w-[350px] transition-transform duration-300 ease-in-out relative min-h-[80vh] pb-[120px]'>
				<div className='absolute right-4'>
					<Close
						className='w-[25px] cursor-pointer'
						onClick={() => {
							setOpenModal(false)
							setStep(0)
						}}
					/>
				</div>
				<h2 className='text-center font-bold text-2xl mt-3 text-myColors-500'>
					{t('tutorial')}
				</h2>
				<p className='text-center mt-10'>{tutorialSteps[step].description}</p>
				<p className='text-center text-[12px] opacity-55'>
					{tutorialSteps[step].title}
				</p>
				<div
					className={`flex justify-center ${
						step === 0 && 'mt-[20%]'
					} p-5 items-center`}
				>
					<img
						src={tutorialSteps[step].image}
						className='rounded-xl'
						alt={`Step ${step + 1}`}
					/>
				</div>

				<div className='flex gap-5 justify-center items-center flex-col mt-6 absolute left-1/2 transform -translate-x-1/2 bottom-[20px]'>
					<div className='flex items-center gap-[10px]'>
						{step > 0 && (
							<button
								className='p-2 px-4 bg-myColors-500 font-bold w-[130px] rounded-3xl'
								style={{ boxShadow: '0 4px 25px rgba(247, 174, 59, 1)' }}
								onClick={() => {
									setStep(prev => prev - 1)
								}}
							>
								{t('previous')}
							</button>
						)}
						<button
							className='p-2 px-4 bg-myColors-500 font-bold w-[130px] rounded-3xl'
							style={{ boxShadow: '0 4px 25px rgba(247, 174, 59, 1)' }}
							onClick={() => {
								if (step === 3) {
									setOpenModal(false)
									setStep(0)
								} else {
									setStep(prev =>
										prev < tutorialSteps.length - 1 ? prev + 1 : prev
									)
								}
							}}
						>
							{step < tutorialSteps.length - 1 ? t('next') : t('done')}
						</button>
					</div>
					<p className='text-center opacity-55'>{step + 1}/4</p>
				</div>
			</div>
		</div>
	)
}

export default TutorialComponent
