import { openLink } from '@telegram-apps/sdk'
import { jwtDecode } from 'jwt-decode'
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import i18n from '../18n'
import Header from '../components/base/header'
import { ReactComponent as Insta } from '../components/images/Insta.svg'
import Loadingg from '../components/images/loadingbg.webp'
import { ReactComponent as Twit } from '../components/images/twitter.svg'
import buildState from '../components/store/buildState'
import useData from '../components/store/dataState'
import gamesState from '../components/store/gamesState'
import useStore from '../components/store/zustand'
import '../components/ui/background.css'
import NavPanel from '../components/ui/NavPanel'
import { MyCityData } from '../models/buildings'
import { data } from '../models/data'
import { games } from '../models/games'
import { missions } from '../models/missions'
import useAchieveApi from '../pages/achievementpage/api/get-acheive'
import useLevelApi from '../pages/levelpage/api/useLevelApi'
import PremiumPackagesApi from '../pages/premiumpage/api/GetPackages'
import useVirtualPickApi from '../pages/virtualpickpage/api/useVirtualPickApi'
import useApi from '../requestProvider/apiHandler'
import { useTelegram } from './useTelegram'
const NavContext = createContext<NavContextType | undefined>(undefined)

interface NavContextType {
	getMissions: () => void
	getBuildings: () => void
	getData: () => void
	getGames: () => void
	initial: () => void
}

export interface idInterface {
	token: string
	first_auth: boolean
}

export const useNecessary = () => {
	const context = useContext(NavContext)

	if (context === undefined) {
		throw new Error('useNecessary must be used within a NavPanelProvider')
	}
	return context
}

interface NavPanelProviderInt {
	children: ReactNode
}

interface decode {
	sub: string
	user_id: number
	role: string
	exp: number
}

export const NavPanelProvider = ({ children }: NavPanelProviderInt) => {
	const location = useLocation()
	const api = useApi()
	const once = useRef<boolean>(false)
	const {
		updateAccessToken,
		updateRole,
		getAccessToken,
		updateBalance,
		updateLng,
		updateLvl,
		updateCoinPerHour,
		role,
		balance,
		coin_per_hour,
		updateDiamonds,
		updateStatus,
		setFirstVisit,
		isFirstVisit,
	} = useStore()
	const { user, userId, name, tg_lg } = useTelegram()
	const { updateMissions } = useData()
	const { updateBuildings, updateTotalFriends } = buildState()
	const { updateGames } = gamesState()
	const token = getAccessToken()
	const { getPremiumPackages } = PremiumPackagesApi()
	const [loading, setLoading] = useState<boolean>(true)
	const { getLevels } = useLevelApi()
	const { getAcheieves } = useAchieveApi()
	const { getVirtualPickPrices } = useVirtualPickApi()
	const changeLanguageAPI = async (culture: string) => {
		await api({
			method: 'PATCH',
			url: `/identity/change_language?culture=${culture}`,
		})
		initial()
	}

	useEffect(() => {
		if (isFirstVisit) {
			try {
				if (isFirstVisit) {
					if (tg_lg === 'en' || tg_lg === 'id' || tg_lg === 'ru') {
						changeLanguageAPI(tg_lg)
						i18n.changeLanguage(tg_lg)
					}
					setFirstVisit(false)
				}
			} catch (e) {
				console.log(e)
			}
		}
	}, [isFirstVisit])

	const getMissions = async (): Promise<void> => {
		if (!token) return
		const res = await api<missions[]>({
			url: '/mission/get-user-missions',
			method: 'GET',
		})
		if (res) {
			updateMissions(res)
		}
	}

	const getData = async () => {
		if (!token) return
		const res = await api<data>({
			method: 'GET',
			url: `/identity/get-data`,
		})
		if (res) {
			i18n.changeLanguage(res?.language)
			updateBalance(res?.current_coin)
			updateLng(res?.language)
			updateLvl(res?.lvl)
			updateCoinPerHour(res?.coin_per_hour)
			updateStatus(res?.status)
			updateDiamonds(res?.diamonds)
		}
	}

	const getId = async (): Promise<void> => {
		const res = await api<idInterface>({
			method: 'POST',
			url: `/identity/sign-in/?user_id=${userId}&username=${
				user !== null ? user : name
			}`,
		})
		if (res) {
			setFirstVisit(res?.first_auth)
			updateAccessToken(res.token)
			const decoded: decode = jwtDecode(res.token)
			updateRole(decoded.role)
		}
	}

	const getBuildings = async (): Promise<void> => {
		if (!token) return
		const res = await api<MyCityData>({
			url: '/building/',
			method: 'GET',
		})
		if (res) {
			updateBuildings(res?.data)
			updateTotalFriends(res?.total_friends)
		}
	}

	const getGames = async () => {
		const res = await api<games[]>({
			url: '/game/',
			method: 'GET',
		})
		if (res) {
			updateGames(res)
		}
	}

	useEffect(() => {
		if (!once.current) {
			getId()
			once.current = true
		}
	}, [])

	const initial = useCallback(async () => {
		await Promise.all([
			getData(),
			getMissions(),
			getBuildings(),
			getPremiumPackages(),
			getLevels(),
			getAcheieves(),
			getVirtualPickPrices(),
		])
		setLoading(false)
		if (role === 'admin') {
			getGames()
		}
	}, [role])

	useEffect(() => {
		if (token) {
			initial()
		}
	}, [token, initial])

	useEffect(() => {
		const coinsPerSecond = coin_per_hour / 3600

		const intervalId = setInterval(() => {
			updateBalance(balance + coinsPerSecond)
		}, 1000)

		return () => clearInterval(intervalId)
	}, [coin_per_hour, balance, updateBalance])

	interface props {
		page: string
	}

	const Background = ({ page }: props) => {
		switch (page) {
			case '/friends':
				return <div className='backgroundFriends' />
			case '/rewards':
				return <div className='backgroundReward' />
			case '/airdrop':
				return <div className='backgroundAirdrop' />
			default:
				return null
		}
	}

	const memoizedHeader = useMemo(() => {
		if (
			location.pathname !== '/Settings' &&
			location.pathname !== '/admin' &&
			location.pathname !== '/premium' &&
			location.pathname !== '/level' &&
			location.pathname !== '/achievements' &&
			location.pathname !== '/virtual-pick'
		) {
			return (
				<>
					<div className='relative'>
						<Header />
					</div>
				</>
			)
		}
		return null
	}, [location.pathname])

	const openSocialNetwork = (status: string) => {
		if (status === 'inst') {
			openLink(
				'https://www.instagram.com/governors_game/profilecard/?igsh=MXJyd2xzOXFrZnB1Zg=='
			)
		} else {
			openLink('https://x.com/governors_game?s=21&t=oKZdJkkZMwuYJJ7HmCWEfw')
		}
	}

	if (loading) {
		return (
			<div className='fixed inset-0 flex items-center justify-center bg-gray-900 z-50'>
				<div className='relative'>
					<img src={Loadingg} alt='' className='h-[100vh] w-[100vh]' />
					<div className='absolute bottom-[10vh] left-[36%] flex gap-2'>
						<button
							className='w-[50px] bg-myColors-750 h-[50px] flex flex-col items-center justify-center rounded-full p-3'
							onClick={() => {
								openSocialNetwork('inst')
							}}
						>
							<Insta />
						</button>

						<button
							className='w-[50px] bg-myColors-750 h-[50px] flex flex-col items-center justify-center rounded-full p-3'
							onClick={() => {
								openSocialNetwork('twitter')
							}}
						>
							<Twit />
						</button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<NavContext.Provider
				value={{ getMissions, getBuildings, getData, getGames, initial }}
			>
				{memoizedHeader}

				{location.pathname === '/rewards' ? (
					<>
						<div
							className={`fixed w-full h-[78vh] bg-myColors-100 rounded-t-[30px] bottom-0`}
						>
							{children}
							<NavPanel />
						</div>
					</>
				) : (
					<>
						{children}
						<NavPanel />
					</>
				)}
			</NavContext.Provider>
		</>
	)
}
