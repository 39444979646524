import useAchieveState, { AchievementInfo, modalInfoType } from '../../../components/store/achieveState'
import useApi from '../../../requestProvider/apiHandler'

export default function useAchieveApi() {
	const api = useApi()
	const {setAllFields, setTypeInfo} = useAchieveState()
	const getAcheieves = async() => {
		const res = await api<AchievementInfo>({
			url: `/achievement/user-achievements`,
			method: 'GET'
		})
		setAllFields(res as AchievementInfo)
	}

	const claimAcheieve= async(id:string) => {
		const res = await api({
			url: `achievement/claim?achievement_id=${id}`,
			method: 'POST'
		})
		getAcheieves()
	}

	const getTypeAchieves= async(type:string) => {
		const res = await api<modalInfoType[]>({
			url: `/achievement/get-all?achievement_type=${type}`,
			method: 'GET'
		})
		setTypeInfo(res as modalInfoType[]);
	}

	return {getAcheieves, claimAcheieve, getTypeAchieves}
}