
import { t } from 'i18next'
import styles from './style.module.css'
export default function Popup() {
	return (
		<div className={styles.popup}>
        <div className={styles.arrowUp}></div>
        <div className={styles.popupContent}>
            <h2>{t('pickup_instruction')}</h2>
        </div>
    </div>
	)
}