import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './18n'
import App from './App'
import './index.css'
import { init } from './init'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
try {
	init()
	root.render(
		<React.StrictMode>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</React.StrictMode>
	)
} catch (e) {
	root.render(<div></div>)
}
