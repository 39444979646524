import { create } from 'zustand'

export type Achievement = {
	id: string
	name: string
	type: string
	color: string
	claimed: boolean
	count: number
	reward: number
	diamonds: number
	buildings: number
	type_translated: string
	name_translated: string
}

export interface modalInfoType {
	type: string
	goal: number
	reward: number
	reward_diamonds: number
	reward_buildings: number
}

export type AchievementInfo = {
	total_claimed_public_safety: number
	total_claimed_health_services: number
	total_claimed_fire_safety: number
	total_claimed_sanitation_waste_management: number
	total_claimed_parks_recreation: number
	total_claimed_transportation: number
	total_claimed_housing_urban_development: number
	total_claimed_sports_wellness: number
	total_claimed_education_culture: number
	total_claimed_environmental_management: number
	achievement: Achievement[]
	typeInfo: modalInfoType[]
}

export interface AchieveState extends AchievementInfo {
	setTotalClaimed: (
		field: keyof Omit<
			AchieveState,
			| 'achievement'
			| 'setTotalClaimed'
			| 'setAchievementClaimed'
			| 'setAllFields'
			| 'setTypeInfo'
		>,
		value: number
	) => void
	setAchievementClaimed: (id: string, claimed: boolean) => void
	setAllFields: (fields: Partial<AchieveState>) => void
	setTypeInfo: (typeInfo: modalInfoType[]) => void
}

const useAchieveState = create<AchieveState>(set => ({
	total_claimed_public_safety: 0,
	total_claimed_health_services: 0,
	total_claimed_fire_safety: 0,
	total_claimed_sanitation_waste_management: 0,
	total_claimed_parks_recreation: 0,
	total_claimed_transportation: 0,
	total_claimed_housing_urban_development: 0,
	total_claimed_sports_wellness: 0,
	total_claimed_education_culture: 0,
	total_claimed_environmental_management: 0,
	achievement: [],
	typeInfo: [],
	setTotalClaimed: (field, value) =>
		set(state => ({
			...state,
			[field]: value,
		})),
	setAchievementClaimed: (id, claimed) =>
		set(state => ({
			...state,
			achievement: state.achievement.map(item =>
				item.id === id ? { ...item, claimed } : item
			),
		})),
	setAllFields: fields =>
		set(state => ({
			...state,
			...fields,
		})),
	setTypeInfo: typeInfo =>
		set(() => ({
			typeInfo,
		})),
}))

export default useAchieveState
