import useStore from '../../../components/store/zustand'
import useApi from '../../../requestProvider/apiHandler'
export interface VirtualPickPrice {level: number, price: number}

export default function useVirtualPickApi() {
	const api = useApi()
	const {setVirtualPickPrices} = useStore()
	const getVirtualPickPrices = async() => {
		const res = await api<VirtualPickPrice[]>({
			url: `/identity/virtual-prices`,
			method: 'POST'
		})
		setVirtualPickPrices(res as VirtualPickPrice[])
	}
	return {getVirtualPickPrices}
}