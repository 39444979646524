import { memo } from 'react'

// Импорт всех иконок
import Garden from '../../images/mycity/cityIcons/1. Garden.webp'
import Library from '../../images/mycity/cityIcons/10. Library.webp'
import Museum from '../../images/mycity/cityIcons/11. Museum.webp'
import Opera from '../../images/mycity/cityIcons/12. Opera.webp'
import BusStation from '../../images/mycity/cityIcons/13. Bus station.webp'
import Metro from '../../images/mycity/cityIcons/14. Metro.webp'
import Airport from '../../images/mycity/cityIcons/15. Airport.webp'
import FirewatchTower from '../../images/mycity/cityIcons/16. Firewatch tower.webp'
import FireHouse from '../../images/mycity/cityIcons/17. Fire house.webp'
import FireStation from '../../images/mycity/cityIcons/18. Fire station.webp'
import Ambulance from '../../images/mycity/cityIcons/19. Ambulance.webp'
import Park from '../../images/mycity/cityIcons/2. Park.webp'
import MedicalClinic from '../../images/mycity/cityIcons/20. Medical clinic.webp'
import Hospital from '../../images/mycity/cityIcons/21. Hospital.webp'
import PoliceStation from '../../images/mycity/cityIcons/22. Police station.webp'
import PoliceDepartment from '../../images/mycity/cityIcons/23. Police department.webp'
import Gis from '../../images/mycity/cityIcons/24. GIS.webp'
import School from '../../images/mycity/cityIcons/25. School.webp'
import College from '../../images/mycity/cityIcons/26. College.webp'
import University from '../../images/mycity/cityIcons/27. University.webp'
import RadioStation from '../../images/mycity/cityIcons/28. Radio station.webp'
import Newspaper from '../../images/mycity/cityIcons/29. Newspaper.webp'
import ThemePark from '../../images/mycity/cityIcons/3. Theme park.webp'
import TVStation from '../../images/mycity/cityIcons/30. TV station.webp'
import CurrencyExchange from '../../images/mycity/cityIcons/31. Currency exchange.webp'
import Bank from '../../images/mycity/cityIcons/32. Bank.webp'
import StockExchange from '../../images/mycity/cityIcons/33. Stock exchange.webp'
import TennisCourt from '../../images/mycity/cityIcons/34. Tennis court.webp'
import BasketballArena from '../../images/mycity/cityIcons/35. Basketball arena.webp'
import Stadium from '../../images/mycity/cityIcons/36. Stadium.webp'
import OilPowerPlant from '../../images/mycity/cityIcons/37. Oil power plant.webp'
import SolarPowerPlant from '../../images/mycity/cityIcons/38. Solar power plant.webp'
import NuclearPowerPlant from '../../images/mycity/cityIcons/39. Nuclear power plant.webp'
import YogaGarden from '../../images/mycity/cityIcons/4. Yoga park.webp'
import Hostel from '../../images/mycity/cityIcons/40. Hostel.webp'
import PlazaHotel from '../../images/mycity/cityIcons/41. Plaza hotel.webp'
import GrandResort from '../../images/mycity/cityIcons/42. Grand resort.webp'
import LocalBingoClub from '../../images/mycity/cityIcons/43. Bingo club.webp'
import PokerClub from '../../images/mycity/cityIcons/44. Poker club.webp'
import Casino from '../../images/mycity/cityIcons/45. Casino.webp'
import SurveillanceSystem from '../../images/mycity/cityIcons/46. Surveillance System (Blue).webp'
import DiagnosticsClinic from '../../images/mycity/cityIcons/47 Diagnostics Clinic (Red).webp'
import FireHelicopterDept from '../../images/mycity/cityIcons/48.  Fire Helicopter Dept. (Yellow).webp'
import Sauna from '../../images/mycity/cityIcons/5. Sauna.webp'
import MonumentPark from '../../images/mycity/cityIcons/50.  Monument Park (Light Green).webp'
import CentralStation from '../../images/mycity/cityIcons/51.  Central Station (Orange).webp'
import ExpoCenter from '../../images/mycity/cityIcons/52.   Expo Center (Brown).webp'
import SkatePark from '../../images/mycity/cityIcons/53.  Skate Park (Teal).webp'
import PerformingArts from '../../images/mycity/cityIcons/54.  Performing Arts (Purple).webp'
import RescueCenter from '../../images/mycity/cityIcons/55. Rescue Center (Grey).webp'
import CommunityPool from '../../images/mycity/cityIcons/6. Community pool.webp'
import GeneralStore from '../../images/mycity/cityIcons/7. General store.webp'
import ShoppingCenter from '../../images/mycity/cityIcons/8. Shopping center.webp'
import Mall from '../../images/mycity/cityIcons/9. City mall.webp'

const iconMap: { [key: string]: string } = {
	hospital: Hospital,
	garden: Garden,
	gis_governors_intelligence_service: Gis,
	police_department: PoliceDepartment,
	police_station: PoliceStation,
	medical_clinic: MedicalClinic,
	park: Park,
	theme_park: ThemePark,
	yoga_garden: YogaGarden,
	sauna: Sauna,
	community_pool: CommunityPool,
	general_store: GeneralStore,
	shopping_center: ShoppingCenter,
	mall: Mall,
	library: Library,
	museum: Museum,
	opera: Opera,
	bus_station: BusStation,
	metro: Metro,
	airport: Airport,
	firewatch_tower: FirewatchTower,
	fire_house: FireHouse,
	fire_station: FireStation,
	ambulance: Ambulance,
	school: School,
	college: College,
	university: University,
	radio_station: RadioStation,
	newspaper: Newspaper,
	tv_station: TVStation,
	currency_exchange: CurrencyExchange,
	bank: Bank,
	stock_exchange: StockExchange,
	tennis_court: TennisCourt,
	basketball_court: BasketballArena,
	stadium: Stadium,
	thermal_power_plant: OilPowerPlant,
	solar_panels: SolarPowerPlant,
	nuclear_power_plant: NuclearPowerPlant,
	hostel: Hostel,
	plaza_hotel: PlazaHotel,
	luxury_resort: GrandResort,
	local_bingo_club: LocalBingoClub,
	poker_club: PokerClub,
	casino: Casino,
	eco_waste_hub: Hospital,
	monument_park: MonumentPark,
	central_station: CentralStation,
	expo_center: ExpoCenter,
	skate_park: SkatePark,
	performing_arts: PerformingArts,
	rescue_center: RescueCenter,
	fire_helicopter_dept: FireHelicopterDept,
	diagnostics_clinic: DiagnosticsClinic,
	surveillance_system: SurveillanceSystem,
}

export const GetIcon = memo(({ iconType }: { iconType: string }) => {
	const formattedIconType = iconType.toLowerCase().replace(/\s+/g, '_')
	const IconSrc = iconMap[formattedIconType]

	return IconSrc ? (
		<img
			src={IconSrc}
			alt={iconType}
			className='w-[70px] h-[70px] rounded-lg'
		/>
	) : null
})
