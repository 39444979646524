import { backButton, invoice } from '@telegram-apps/sdk'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useInvoiceApi from '../../components/features/premiumpage/api/getInvoice'
import Arrow from '../../components/images/arrow.svg'
import Spinner from '../../components/images/spinner2.svg'
import useStore from '../../components/store/zustand'
import GetPointsApi from '../../components/ui/homepage/Map/generateLocations/api/get-points'

const VirtualPickPage = () => {
	const { virtualClick, maxVirtualClick, virtualPickPrices } = useStore()
	const [loading, setLoading] = useState(false)
	const { getInvoiceAddress } = useInvoiceApi()
	const { getPoints } = GetPointsApi()
	const intervalRef = useRef<NodeJS.Timeout | null>(null)
	const callCountRef = useRef(0)
	const navigate = useNavigate()
	const [spinner, setSpinner] = useState<boolean>(false)
	const { t } = useTranslation()

	const handleClick = async (price: number, level: number) => {
		setLoading(true)
		setSpinner(true)
		const res = await getInvoiceAddress(price, 1, `vc_${level}`)

		if (res) {
			setSpinner(false)
			const invoiceUrl = res.replace('https://t.me/$', '')
			await invoice.open(invoiceUrl)
		}

		if (intervalRef.current) {
			clearInterval(intervalRef.current)
		}

		callCountRef.current = 0

		intervalRef.current = setInterval(() => {
			if (callCountRef.current >= 4) {
				clearInterval(intervalRef.current!)
				return
			}
			getPoints()
			callCountRef.current += 1
		}, 4000)

		setLoading(false)
	}

	useEffect(() => {
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
			}
		}
	}, [])

	useEffect(() => {
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
			}
		}
	}, [])

	useEffect(() => {
		const handleBackButton = () => {
			navigate(-1)
		}
		if (backButton) {
			backButton.show()
			backButton.onClick(handleBackButton)

			return () => {
				if (backButton) {
					backButton.offClick(handleBackButton)
					backButton.hide()
				}
			}
		}
	}, [backButton])

	return (
		<div className='w-full px-5 mt-[10vh]'>
			<div className='w-full relative'>
				<div className='flex flex-col items-center justify-center h-[45px]'>
					<p className='text-center text-2xl font-bold text-myColors-250'>
						{t('virtualPickUp')}
					</p>
				</div>
				<div className='rounded-2xl bg-black w-[50px] h-[50px] flex justify-center items-center bg-opacity-55 cursor-pointer flex-col leading-none absolute top-0 right-0'>
					<p>🚁</p>
					<p className='text-myColors-250'>
						{virtualClick}/{maxVirtualClick}
					</p>
				</div>
			</div>

			<div className='flex flex-col gap-3 mt-5 font-bold text-white'>
				{virtualPickPrices.map((item, index) => (
					<button
						key={index}
						className='w-full px-3 bg-myColors-200 p-3 rounded-[20px] flex justify-between items-center h-[64px]'
						disabled={loading}
						onClick={() => handleClick(item.price, item.level)}
					>
						<div className='flex gap-5 items-center'>
							<p className='text-myColors-250 text-lg'>🚁 {item.level}</p>
							<p className='text-[12px]'>
								{t('buy')} {item.level} {t('virtualPickUps')} - {item.price}⭐
							</p>
						</div>
						<img
							src={Arrow}
							alt='arrow'
							className='w-[23px] h-[23px] pt-[2px]'
						/>
					</button>
				))}
				<div className='flex w-full justify-center'>
					{spinner && (
						<img src={Spinner} alt='spinner' className='w-[100px] h-[100px]' />
					)}
				</div>
			</div>
		</div>
	)
}
export default VirtualPickPage
