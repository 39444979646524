import PreventRobbery from '../../../../../components/images/localgameicons/1.1 Prevent Robbery.webp'
import ReportUnsafe from '../../../../../components/images/localgameicons/1.2 Report Unsafe Areas.webp'
import InspectCrime from '../../../../../components/images/localgameicons/1.3 Inspect Crime Scene.webp'
import InspectEvacuationRoutes from '../../../../../components/images/localgameicons/10.1  Inspecting Evacuation Routes.webp'
import AssistFloodPreparation from '../../../../../components/images/localgameicons/10.2 Assist with Flood Preparation.webp'
import ConductSafetyAwareness from '../../../../../components/images/localgameicons/10.3 Conduct Safety Awareness.webp'
import ProvideZitizen from '../../../../../components/images/localgameicons/2.1  Provide First Aid to a Citizen.webp'
import DeliverMedicalPills from '../../../../../components/images/localgameicons/2.2 Deliver Medical Pills to an Older Person.webp'
import AssistHealthCamp from '../../../../../components/images/localgameicons/2.3 Assist at a Health Camp.webp'
import ExtinguishFire from '../../../../../components/images/localgameicons/3.1 Extinguish a Small Fire.webp'
import InspectFireAlarm from '../../../../../components/images/localgameicons/3.2 Inspect Fire Alarm Readiness.webp'
import PromoteFireSafety from '../../../../../components/images/localgameicons/3.3 Promote Fire Safety Tips.webp'
import FixTrashBin from '../../../../../components/images/localgameicons/4.1 Fix an Overflowing Trash Bin.webp'
import EncourageRecycling from '../../../../../components/images/localgameicons/4.2 Encourage Waste Recycling.webp'
import MonitorCleanliness from '../../../../../components/images/localgameicons/4.3 Monitor a Cleanliness Drive.webp'
import OrganizeFestival from '../../../../../components/images/localgameicons/5.1 Organize a Local Festival.webp'
import ProposePublicSpace from '../../../../../components/images/localgameicons/5.2 Propose a Space for Public Gatherings.webp'
import EncourageFeedback from '../../../../../components/images/localgameicons/5.3 Encourage Citizen Feedback.webp'
import InspectTransitStop from '../../../../../components/images/localgameicons/6.1 Inspect a Potential Transit Stop.webp'
import ReportTrafficCongestion from '../../../../../components/images/localgameicons/6.2 Report Traffic Congestion Issues.webp'
import PromoteCrosswalkSafety from '../../../../../components/images/localgameicons/6.3 Promote Safer Crosswalks.webp'
import ScoutMarketplaces from '../../../../../components/images/localgameicons/7.1 Scout for New Marketplaces.webp'
import InspectUtilityAccess from '../../../../../components/images/localgameicons/7.2 Inspect Utility Access.webp'
import AssessUrbanMobility from '../../../../../components/images/localgameicons/7.3 Assess Urban Mobility Needs.webp'
import OrganizeYoga from '../../../../../components/images/localgameicons/8.1 Organize a Yoga Session.webp'
import PromoteOutdoorFitness from '../../../../../components/images/localgameicons/8.2 Promote Outdoor Fitness Equipment.webp'
import ScoutSportsFacility from '../../../../../components/images/localgameicons/8.3 Scout for New Sports Facility.webp'
import SetUpCommunityLibrary from '../../../../../components/images/localgameicons/9.1 Help Set Up a Community Library,.webp'
import IdentifyCulturalEventSpots from '../../../../../components/images/localgameicons/9.2 Identify Spots for Cultural Events.webp'
import PromoteHistoricalSites from '../../../../../components/images/localgameicons/9.3 Promote Local Historical Sites.webp'

interface IconProps {
  icon: string;
  color: any;
}

export const GetIcon = ({ icon, color }: IconProps) => {
  switch (icon) {
    case '1.1':
      return <img src={PreventRobbery} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '1.2':
      return <img src={ReportUnsafe} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '1.3':
      return <img src={InspectCrime} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '2.1':
      return <img src={ProvideZitizen} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '2.2':
      return <img src={DeliverMedicalPills} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '2.3':
      return <img src={AssistHealthCamp} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '3.1':
      return <img src={ExtinguishFire} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '3.2':
      return <img src={InspectFireAlarm} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '3.3':
      return <img src={PromoteFireSafety} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '4.1':
      return <img src={FixTrashBin} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '4.2':
      return <img src={EncourageRecycling} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '4.3':
      return <img src={MonitorCleanliness} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '5.1':
      return <img src={OrganizeFestival} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '5.2':
      return <img src={ProposePublicSpace} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '5.3':
      return <img src={EncourageFeedback} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '6.1':
      return <img src={InspectTransitStop} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '6.2':
      return <img src={ReportTrafficCongestion} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '6.3':
      return <img src={PromoteCrosswalkSafety} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '7.1':
      return <img src={ScoutMarketplaces} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '7.2':
      return <img src={InspectUtilityAccess} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '7.3':
      return <img src={AssessUrbanMobility} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '8.1':
      return <img src={OrganizeYoga} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '8.2':
      return <img src={PromoteOutdoorFitness} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '8.3':
      return <img src={ScoutSportsFacility} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '9.1':
      return <img src={SetUpCommunityLibrary} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '9.2':
      return <img src={IdentifyCulturalEventSpots} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '9.3':
      return <img src={PromoteHistoricalSites} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '10.1':
      return <img src={InspectEvacuationRoutes} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '10.2':
      return <img src={AssistFloodPreparation} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    case '10.3':
      return <img src={ConductSafetyAwareness} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
    default:
      return <img src={PromoteHistoricalSites} alt="" className='rounded-[9px] w-[90px] h-[90px]' style={{ border: `3px solid ${color}` }} />;
  }
};
