import {
	disableVerticalSwipes,
	expandViewport,
	viewport,
} from '@telegram-apps/sdk'
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react'
import { useEffect } from 'react'
import Desktop from './pages/desktop'
import { ErrorProvider, useError } from './requestProvider/errorContext'
import ErrorSnackbar from './requestProvider/errorSnackbar'
import Router from './router/router'
function App() {
	const userAgent = window.navigator.userAgent.toLowerCase()
	const isMobile = /android|iphone|ipod/.test(userAgent)

	useEffect(() => {
		if (viewport.expand.isAvailable()) {
			expandViewport()
		}
		if (disableVerticalSwipes.isAvailable()) {
			disableVerticalSwipes()
		}
	}, [])

	if (!isMobile) {
		return <Desktop />
	}

	return (
		<TonConnectUIProvider
			manifestUrl='https://tgmochispa.devmainops.store/tonconnect-manifest.json'
			uiPreferences={{ theme: THEME.DARK }}
			actionsConfiguration={{
				twaReturnUrl: 'https://t.me/govuatbot',
			}}
		>
			<ErrorProvider>
				<AppContext />
			</ErrorProvider>
		</TonConnectUIProvider>
	)
}

const AppContext = () => {
	const { error, setError } = useError()

	return (
		<div id='app'>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					maxHeight: '100vh',
				}}
			>
				<div style={{ flex: 1 }}>
					<Router />
					<ErrorSnackbar error={error} onClose={() => setError('')} />
				</div>
			</div>
		</div>
	)
}

export default App
