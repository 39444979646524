import { invoice } from '@telegram-apps/sdk'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetPremiumPackagesInt } from '../../../pages/premiumpage/types/PremiumPackagesInt'
import Close from '../../images/airdrop/close.svg'
import Spinner from '../../images/spinner2.svg'
import useInvoiceApi from './api/getInvoice'
interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	modalInfo: GetPremiumPackagesInt
}

export default function PremiumModal({ isOpen, setIsOpen, modalInfo }: Props) {
	const [loading, setLoading] = useState(false)
	const { getInvoiceAddress } = useInvoiceApi()
	const { t } = useTranslation()

	const [spinner, setSpinner] = useState<boolean>(false)

	/* 	useEffect(() => {
    const userValue = modalInfo?.price
		const starCost = userValue / (0.625 / 50);

		if (!isNaN(userValue) && userValue > 0) {
			setStars(starCost);
		} else {
			setStars(0);
		}
  }, []) */
	const handleClick = async () => {
		setLoading(true)
		setSpinner(true)
		const res = await getInvoiceAddress(modalInfo?.price, 0, modalInfo.name)
		if (res) {
			setSpinner(false)
			const invoiceUrl = res.replace('https://t.me/$', '')
			await invoice.open(invoiceUrl)
		}
		setLoading(false)
	}
	const bonusItems = modalInfo?.bonus.split('/').map(item => item.trim())
	return (
		<div>
			{isOpen && (
				<div
					className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 text-white transition-opacity duration-300 ease-in-out`}
				>
					<div
						className={`bg-myColors-800 border border-myColors-850 rounded-[35px] py-2 pb-4 px-4 md:max-w-md mx-2 z-60 transform w-[350px] transition-transform duration-300 ease-in-out`}
					>
						<div className='flex justify-end'>
							<button
								className='w-[30px] h-[30px] flex justify-center items-center'
								onClick={() => setIsOpen(false)}
							>
								<img src={Close} alt='' className='w-[30px] h-[30px]' />
							</button>
						</div>
						<p className='text-center font-bold text-xl'>
							{modalInfo?.name_translated} <br /> {modalInfo?.price}⭐
						</p>
						<div className='flex justify-center flex-col mt-5 px-10'>
							{bonusItems.map((bonus, index) => (
								<p key={index}>- {bonus}</p>
							))}
						</div>
						<div className='flex gap-5 justify-center items-center mt-10'>
							<button
								className='p-2 px-4 bg-myColors-500 font-bold w-[130px] rounded-3xl flex justify-center items-center'
								style={{ boxShadow: '0 4px 25px rgba(247, 174, 59, 1)' }}
								onClick={handleClick}
								disabled={loading}
							>
								{spinner ? (
									<img
										src={Spinner}
										className='h-[25px] w-[100px]'
										alt='spinner'
									/>
								) : (
									t('buy')
								)}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
